export class AgencyPostModel {
    AgencyId: number;
    AgencyName: string;
    TaxId: string;
    AccountNumber: string;
    IsGeneralAgency: boolean;
    AddressId: number;
    SelectedAddressTypes: number[] = [];
    LocationName: string;
    AddressLineOne: string;
    City: string;
    State: number;
    County: number;
    Zip: string;
    AgencyAddressId: number;
    BillingAddressEmail: string;
    ShowBillingEmail: boolean;
    FirstName: string;
    LastName: string;
    PrimaryEmail: string;
    Mobile: string;
    Phone: string;
    RecoveryEmail: string;
    AgencyContactPersonId: number;
    UserId: string;
    SubscriptionPlanId: number;
    SalesCodeId: number;
    ZipCodeId: number;
    isZipCodeExist: boolean;
    SalesCodeName: string;
    IsActive :boolean;
}