<form>
    <div style="text-align:center">
        <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            <h2>
                Account ID: {{accountNumber}}
            </h2>
            <h2>
                The Agency and Agency Administrator were submitted successfully.
            </h2>
            <h2 style="display:inline">
                The Agency Administrator will receive an email to activate their account.
            </h2>
        </div>
    </div>
</form>







