import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

export enum HttpReqMethod {
  POST,
  GET,
  DELETE,
  PUT,
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private httpClient: HttpClient
  ) {}

  public Request<T>(
    url: string,
    method: string,
    body?: object,
    parameters?: HttpParams,
    headers?: HttpHeaders
  ) {
    try {
      if (method === HttpReqMethod.GET.toString()) {
        if (parameters) {
          return this.httpClient.get<T>(url, { params: parameters });
        }
        return this.httpClient.get<T>(url);
      } else if (method === HttpReqMethod.POST.toString()) {
        return this.httpClient.post<T>(url, body, { headers: headers});
      } else if (method === HttpReqMethod.DELETE.toString()) {
        return this.httpClient.delete<T>(url);
      } else if (method === HttpReqMethod.PUT.toString()) {
        return this.httpClient.put<T>(url, body);
      }
    } catch (error) {
      this.LogError("Error In api.service.ts at Method: Request. " + error);
    }
  }

  public async LogError(exception: string) {
    const error = new Error(exception);
    this.CallError(exception).subscribe((x) => {});
  }

  public CallError(exception: string): Observable<any> {
    return this.httpClient.get<any>(
      environment.ADMIN_SERVICE_HOST +
        "api/error/logerror?exception=" +
        exception
    );
  }
}
