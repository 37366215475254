import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './successfulsignup.component.html',
  styleUrls: ['./successfulsignup.component.scss']
})
export class SuccessfulSignupComponent implements OnInit {
  agencySignUpForm: FormGroup;
  accountNumber: string;
  

  constructor(@Inject(DOCUMENT) private document: Document, private activateRoute: ActivatedRoute,
    private fb: FormBuilder, private router: Router) {

      this.activateRoute.paramMap.subscribe((params) => {
        this.accountNumber = params.get("accountNumber"); //+ string to number
      });
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
