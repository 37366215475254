import { Injectable } from "@angular/core";
import { ApiService, HttpReqMethod } from "./api.service";
import { AgencyPostModel } from "../shared/models/AgencyPostModel";
//import { TermAndConditionModel } from "../shared/models/AgencySignUp/TermAndConditionModel";
//import { RoleTypeEnum } from "../shared/enums/RolesType";
import { HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class AgencySignUpService {
  constructor(private apiService: ApiService) {}
  url = environment.AGENCIES_SERVICE_HOST + "api/agencySignUp/";
  agencyUrl = environment.AGENCIES_SERVICE_HOST;
  adminUrl = environment.ADMIN_SERVICE_HOST;
  agencySignUpApiKey = environment.AGENCY_SIGNUP_APIKEY.toString();

  GetStates() {
    return this.apiService.Request<any>(
      this.adminUrl + "api/Data/GetStates",
      HttpReqMethod.GET.toString()
    );
  }

  GetAddressTypes() {
    return this.apiService.Request<any>(
      this.adminUrl + "api/AddressType/" + "GetAddressTypesList",
      HttpReqMethod.GET.toString()
    );
  }

  GetstateWiseCounties(stateId: number) {
    return this.apiService.Request<any>(
      this.adminUrl + "api/County/" + "StatewideCounties?stateId=" + stateId,
      HttpReqMethod.GET.toString()
    );
  }

  // get counties list against state id and zip code
  GetStateAndZipWiseCounties(stateId: number, zipCode: string) {
    return this.apiService.Request<any>(
      this.adminUrl +
        "api/County/GetZipCodeBasedCounties?stateId=" +
        stateId +
        "&zipCode=" +
        zipCode,
      HttpReqMethod.GET.toString()
    );
  }

  IsUserExitsWithSameEmail(
    email: string,
    encryptedUserId: string,
    agencyContactPersonId: number
  ) {
    if (email) {
      const model = {
        Email: email,
        UserId: encryptedUserId,
        RoleType: "Agency User",
        AgencyContactPersonId: agencyContactPersonId,
      };
      const encObj = JSON.stringify(model)
      .replace(/\{/g, "{")
      .replace(/\}/g, "}");
    const payload = { body: encObj };
      return this.apiService.Request<any>(
        this.url + "IsUserExist",
        HttpReqMethod.POST.toString(),
        payload
      );
    }
  }

  SaveUpdateAgency(model: AgencyPostModel) {
    const agencyModel = JSON.parse(JSON.stringify(model));
    if (agencyModel.TaxId) {
      agencyModel.TaxId = model.TaxId;
    }
    if (agencyModel.BillingAddressEmail) {
      agencyModel.BillingAddressEmail = model.BillingAddressEmail;
    }
    if (agencyModel.Mobile) {
      agencyModel.Mobile = ("" + model.Mobile).replace(/\D/g, "");
    }
    if (agencyModel.Phone) {
      agencyModel.Phone = ("" + model.Phone).replace(/\D/g, "");
    }

    const headers = new HttpHeaders({
      "AgencySignUpApiKey": this.agencySignUpApiKey
    });

    const encObj = JSON.stringify(agencyModel)
      .replace(/\{/g, "{")
      .replace(/\}/g, "}");
    const payload = { body: encObj };

    return this.apiService.Request<any>(
      this.url + "SavePublicSignUpAgency",
      HttpReqMethod.POST.toString(),
      payload,null,headers
    );
  }

  checkTaxIdExists(taxId: string, agencyId: number) {
    const encryptedTaxId = taxId;
    const encryptedAgencyId = agencyId.toString();
    return this.apiService.Request<any>(
      this.agencyUrl +
        "api/agencies/DoesTaxIdExist?taxId=" +
        encryptedTaxId +
        "&agencyId=" +
        encryptedAgencyId,
      HttpReqMethod.GET.toString()
    );
  }

  GetAgencyDetailsFromId(agencyId: number, agencyContactPersonId: number) {
    const model = {
      AgencyId: agencyId,
      AgencyContactPersonId: agencyContactPersonId,
    };
    const encObj = JSON.stringify(model)
      .replace(/\{/g, "{{")
      .replace(/\}/g, "}}");
    const payload = { body: encObj };
    return this.apiService.Request<any>(
      this.url + "GetAgencySignUpDetails",
      HttpReqMethod.POST.toString(),
      payload
    );
  }

}