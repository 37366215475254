import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AgencyPostModel } from '../shared/models/AgencyPostModel';
import { AgencySignUpService } from '../services/agency-sign-up.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  agencySignUpForm: FormGroup;
  siteKey = "6Lfq0LsiAAAAACXlsaKWhtkAluKwL1HvSAsZvajj";
  agencyModel: AgencyPostModel = new AgencyPostModel();
  agencyId: number;
  private success = new Subject<string>();
  private error = new Subject<string>();
  submitDisable: boolean;
  alertMessage: string;
  staticAlertClosed = false;
  successMessage: string;
  errorMessage: string;
  salesCodeName: string;
  agencyContactPersonId = 0;
  isZipCodeExist: boolean;
  isLoading: boolean = false;
  states: any[];
  counties: any[];
  @Output() showSave = new EventEmitter();
  /*phone masking*/
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  /*phone validation*/

  constructor(@Inject(DOCUMENT) private document: Document, private activateRoute: ActivatedRoute,
    private fb: FormBuilder, private agencySignUpService: AgencySignUpService, private apiService: ApiService,
    private router: Router) {
    this.agencyId = 0;
    this.agencyModel.State = null;
    this.agencyModel.County = null;
    this.siteKey = environment.CAPTCHA_SITEKEY.toString();
  }

  ngOnInit() {
    window.scroll(0, 0);
    setTimeout(() => this.staticAlertClosed = true, 50000);
    this.success.subscribe(
      (message) => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = "");
    // displaying error msg
    this.error.subscribe((message) => this.errorMessage = message);
    this.error.pipe(
      debounceTime(5000)
    ).subscribe(() => this.errorMessage = "");
    this.createForm();
    this.ClearForm();
    this.activateRoute.queryParams.subscribe(params => {
      if (params['salescodename'] !== undefined) {
        this.salesCodeName = params['salescodename'];
        this.agencyModel.SalesCodeName = this.salesCodeName;
      }
      this.LoadAddressTypes();
      this.GetStates();
    });
  }

  createForm() {
    this.agencySignUpForm = this.fb.group({
      agencyName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 \'.&_-]+$')]],
      taxId: ['', [Validators.pattern(/^\d{2}-\d{7}$/)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      county: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      primaryEmail: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9\'.+_-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z0-9-_]{1,}')]],
      recoveryEmail: ['', [Validators.email, Validators.pattern('[a-zA-Z0-9\'.+_-]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z0-9-_]{1,}')]],
      mobile: ['', Validators.required],
      phone: [''],
      recaptcha: ['', Validators.required]
    });
  }

  ClearForm() {
    this.agencySignUpForm.reset();
  }

  // Restrict some special characters in Search input
  RestrictTaxIdChars(event: any) {
    // Shift, control and alternate keys do not effect input
    if (event.shiftKey || event.ctrlKey || event.altKey) {
      event.preventDefault();
    }
    const pattern = /^[0-9\'-]*$/;
    if (event.inputType !== 'deleteContentBackward') {
      if (!pattern.test(event.target.value)) {
        this.agencyModel.TaxId = event.target.value.replace(/[^0-9\'-]/g, '');
        event.target.value = event.target.value.replace(/[^0-9\'-]/g, '');
      }
      if (event.target.value.length === 3 && event.target.value.indexOf('-') === -1) {
        const e = event.target.value.substring(0, 2);
        const e1 = event.target.value.substring(2);
        this.agencyModel.TaxId = e + '-' + e1;
      }

      if (this.agencyModel.TaxId !== null && this.agencyModel.TaxId !== undefined) {
        if (this.agencyModel.TaxId.length === 2) {
          if (pattern.test(this.agencyModel.TaxId)) {
            this.agencyModel.TaxId = this.agencyModel.TaxId + '-';
          }
        }
      }
    }
  }

  ResetInvalidZip() {
    this.isZipCodeExist = false;
    this.agencyModel.ZipCodeId = null;
  }

  LoadAddressTypes() {
    this.agencyModel.ShowBillingEmail = true;
    this.agencyModel.SelectedAddressTypes = [1, 2, 3];
  }
  GetStates() {
    try {
      this.agencySignUpService.GetStates().subscribe(data => {
        if (data != null && data !== undefined) {
          this.states = data;
          if (this.agencyId > 0 && this.agencyContactPersonId) {
            this.GetAgencyById();
          }
        }
      }, error => {
      });
    } catch (error) {
      this.apiService.LogError('Error in agency-signup.component.ts in Method: GetStates. ' + error);
    }
  }

  GetAgencyById() {
    try {
      this.agencySignUpService.GetAgencyDetailsFromId(this.agencyId, this.agencyContactPersonId).subscribe({
        next: data => {
          if (data !== null) {
            if (data.data) {
              const parsedata = JSON.parse(data.data);

              const promise = new Promise<void>((resolve, reject) => {
                if (parsedata.State > 0 && parsedata.Zip !== '' && parsedata.Zip !== null) {
                  this.GetCountiesByStateAndZip(Number(parsedata.State), parsedata.Zip, true);
                }
                this.agencyModel = parsedata;
                resolve();
              });

            }
          }
        }, error: error => {
          if (error.error.type === 'error') {
            this.error.next('Something went wrong, please try again.');
          }
          this.error.next(error.error.errorMessage);
        }
      });
    } catch (error) {
      this.apiService.LogError('Error in agency-signup.component.ts in Method: GetAgencyById. ' + error);
    }
  }

  /*state selection  change event  */
  SelectState(stateId: number) {
    try {
      this.counties = [];
      if (stateId > 0) {
        this.GetCounties(stateId);
      } else {
        this.agencySignUpForm.controls['state'].setErrors({ required: true })
      }
      this.agencyModel.County = null;
      this.agencyModel.Zip = null;
      this.agencyModel.ZipCodeId = 0;
      this.isZipCodeExist = false;
    } catch (error) {
      this.apiService.LogError('Error in agency-signup.component.ts in Method: SelectState. ' + error);
    }
  }

  GetCounties(stateId: number) {
    try {
      this.agencySignUpService.GetstateWiseCounties(stateId).subscribe({
        next: data => {
          if (data !== null && data !== undefined) {
            this.counties = data;
          }
        }, error: error => {
          if (error.error.type === 'error') {
            this.error.next('Something went wrong, please try again.');
          } else {
            this.error.next(error.error.errorMessage);
          }
        }
      });
    } catch (error) {
      this.apiService.LogError('Error in agency-signup.component.ts in Method: GetCounties. ' + error);
    }
  }

  // onchange event on zip textbox
  getCountyList(inputZipCode: string) {
    if (inputZipCode !== '' && inputZipCode !== null && inputZipCode !== undefined) {
      const zipCode = inputZipCode.trim();
      const stateId = this.agencyModel.State;

      if (zipCode !== '' && stateId > 0) {
        this.GetCountiesByStateAndZip(stateId, zipCode, false);
      }
    } else {
      const state = parseInt(this.agencyModel.State.toString(), 0);
      if (state > 0) {
        this.agencyModel.County = null;
        this.GetCounties(this.agencyModel.State);
      }
    }
  }

  /*Gets state and zip wise counties */
  GetCountiesByStateAndZip(stateId: number, zipCode: string, onEdit: boolean) {
    try {
      this.agencySignUpService.GetStateAndZipWiseCounties(stateId, zipCode).subscribe({
        next: data => {
          if (data !== null && data !== undefined && data.length > 0) {
            // zipcode valid block
            this.counties = data;
            if (data.length === 1) {
              this.agencyModel.County = data[0].countyId;
            } else if (!onEdit) {
              this.agencyModel.County = null;
              this.agencySignUpForm.controls['county'].setErrors({ touched: true })
            }
            this.agencyModel.ZipCodeId = data[0].zipCodeId;
            this.isZipCodeExist = true;
          } else {
            // zip invalid block
            this.agencyModel.County = null;
            this.GetCounties(stateId);
            this.agencySignUpForm.controls['zip'].setErrors({ touched: true })
          }
        }, error: error => {
          if (error.error.type === 'error') {
            this.error.next('Something went wrong, please try again.');
          } else {
            this.error.next(error.error.errorMessage);
          }
        }
      });
    } catch (error) {
      this.apiService.LogError('Error in agency-signup.component.ts in Method: GetCountiesByStateAndZip. ' + error);
    }
  }

  OnCountyDeselect(countyId: number) {
    if (countyId > 0) {
      return false;
    } else {
      this.agencySignUpForm.controls['county'].setErrors({ required: true })
    }
  }


  OnSubmit(contentAlert: any) {
      this.isLoading = true;
      this.MarkControlsAsTouched(this.agencySignUpForm);
      this.submitDisable = true;
      if (this.agencySignUpForm.invalid) {
        this.submitDisable = false;
        this.alertMessage = 'Please correct the invalid data before moving to the next screen.';
        this.isLoading = false;
        this.showSave.emit(this.alertMessage);
        return;
      } else if (this.agencyModel.ZipCodeId === null || this.agencyModel.ZipCodeId === 0 || this.agencyModel.ZipCodeId === undefined) {
        this.submitDisable = false;
        return false;
      }
      try {
        this.agencyModel.AgencyId = 0;
        this.agencySignUpService.checkTaxIdExists(this.agencyModel.TaxId, 0).subscribe({
          next: response => {
            if (response) {
              this.AddTaxExistError(response);
              this.submitDisable = false;
              this.isLoading = false;
            }
            else {
              this.agencySignUpService.IsUserExitsWithSameEmail(this.agencyModel.PrimaryEmail, this.agencyModel.UserId,
                this.agencyContactPersonId).subscribe({
                  next: data1 => {
                    if (data1 === true) {
                      this.agencySignUpForm.controls['primaryEmail'].setErrors({ emailExist: true });
                      this.submitDisable = false;
                      this.isLoading = false;
                    } else {
                      this.agencySignUpForm.controls['primaryEmail'].setErrors(null);
                      this.agencySignUpForm.controls['primaryEmail'].updateValueAndValidity();
                      this.agencyModel.LocationName = "Main Office";
                      this.agencyModel.IsGeneralAgency = false;
                      this.agencyModel.IsActive = true;
                      this.agencySignUpService.SaveUpdateAgency(this.agencyModel).subscribe({
                        next: data => {
                          if (data.status !== null && data.status !== '') {
                            window.scroll(0, 0);
                            this.submitDisable = true;
                            this.isLoading = false;
                            setTimeout(() => {
                              this.router.navigate(['success',data.accountNumber]); 
                            }, 0)
                          } else {
                          }
                        }, error: error => {
                          this.isLoading = false;
                          this.submitDisable = false;
                          if (error.error.type === 'error') {
                            this.error.next('Something went wrong, please try again.');
                          } else if (error.error.duplicateSubscriptionError !== null
                            && error.error.duplicateSubscriptionError !== undefined
                            && error.error.duplicateSubscriptionError !== '') {
                            this.error.next(error.error.duplicateSubscriptionError);
                          } else {
                            this.error.next(error.error.errorMessage);
                          }
                        }
                      });
                    }
                  }, error: error => {
                    this.isLoading = false;
                    if (error.error.type === 'error') {
                      this.error.next('Something went wrong, please try again.');
                    }
                    this.error.next(error.error.errorMessage);
                  }
                });
            }
          }, error: error => {
            this.isLoading = false;
            this.submitDisable = false;
          }
        });
      } catch (error) {
        this.isLoading = false;
        this.apiService.LogError('Error in agency-signup.component.ts in Method: OnSubmit. ' + error);
      }
  }
  /*Mark all form controls as touched for validation */
  MarkControlsAsTouched(formRef: FormGroup) {
    for (const property in formRef.controls) {
      if (property) {
        formRef.controls[property].markAsTouched();
      }
    }
  }

  AddTaxExistError(error: boolean) {
    if (error === true) {
      this.agencySignUpForm.controls['taxId'].setErrors({ notExist: true });
    } else {
      this.agencySignUpForm.controls['taxId'].setErrors({ notExist: true });
      this.agencySignUpForm.controls['taxId'].updateValueAndValidity();
    }
  }

  isNumberKey(event: any) {
    // Shift, control and alternate keys do not effect input
    if (event.shiftKey || event.ctrlKey || event.altKey) {
      event.preventDefault();
    } else {
      const pattern = /^[0-9]*$/;
      if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.agencyModel.Zip = event.target.value;
      }
    }
    if (this.agencyModel.Zip === '' || this.agencyModel.Zip === null ||
      this.agencyModel.Zip === undefined) {
      this.agencySignUpForm.controls['Zip'].setErrors({ required: true })
    }
  }

  // format mobile or phone number
  formatPhoneNumber(phoneNumberString: { valid: any; value: string | any[]; }, input: number) {
    if (phoneNumberString.valid && phoneNumberString.value !== null && phoneNumberString.value !== undefined &&
      phoneNumberString.value.length > 1) {
      const cleaned = ('' + phoneNumberString.value).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        if (input === 1) {
          this.agencySignUpForm.controls['mobile'].setErrors({ pattern: true });
          this.agencySignUpForm.controls['mobile'].updateValueAndValidity();
        } else {
          this.agencySignUpForm.controls['phone'].setErrors({ pattern: true })
          this.agencySignUpForm.controls['phone'].updateValueAndValidity();
        }
      } else {
        if (input === 1) {
          this.agencySignUpForm.controls['mobile'].setErrors({ pattern: true })

        } else {
          this.agencySignUpForm.controls['phone'].setErrors({ pattern: true })
        }
      }
    } else {
      if (input === 1) {
        this.agencySignUpForm.controls['mobile'].setErrors({ pattern: true })
        this.agencySignUpForm.controls['mobile'].updateValueAndValidity();
      } else {
        this.agencySignUpForm.controls['phone'].setErrors({ pattern: true })
        this.agencySignUpForm.controls['phone'].updateValueAndValidity();
      }
    }
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
  }
}
