<!-- CSS only -->
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
<div *ngIf=isLoading class="spinner-overlay">
  <mat-spinner *ngIf=isLoading></mat-spinner>
</div>  
<form [formGroup]="agencySignUpForm" (ngSubmit)="OnSubmit(contentAlert)">
  <div class="container">
    <div class="content" role="main">
      <h2>
        <i class="fa-solid fa-play"></i>
        Agency
      </h2>
      <div class="form agency-form">
        <mat-form-field class="name">
          <mat-label>Agency Name</mat-label>
          <input matInput formControlName="agencyName" id="agencyName" appNfNoSpaces type="text"
                 [(ngModel)]="agencyModel.AgencyName" placeholder="Agency Name" maxlength="100">
          <mat-error *ngIf="agencySignUpForm.controls['agencyName'].invalid && (agencySignUpForm.controls['agencyName'].dirty || agencySignUpForm.controls['agencyName'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['agencyName'].errors['required'] || agencySignUpForm.controls['agencyName'].errors['whitespace']">
              Agency name is required.
            </mat-error>
            <mat-error *ngIf="agencySignUpForm.controls['agencyName'].errors['notExist']">
              Agency name entered already exists in system, please try with different name.
            </mat-error>
            <mat-error *ngIf="(agencySignUpForm.controls['agencyName'].errors['pattern']) &&  !agencySignUpForm.controls['agencyName'].errors['whitespace']">
              Agency name should contain alphabets, numbers and following special characters (', - , _ , &).
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="taxId">
          <mat-label>Tax ID</mat-label>
          <input matInput formControlName="taxId" appNfNoSpaces type="text" placeholder="11-1111111"
                 [(ngModel)]="agencyModel.TaxId" maxlength="10" (input)="RestrictTaxIdChars($event);">
          <mat-error *ngIf="agencySignUpForm.controls['taxId'].invalid && (agencySignUpForm.controls['taxId'].dirty || agencySignUpForm.controls['taxId'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['taxId'].errors['notExist']">
              Tax ID entered already exists in system, please try with different tax id.
            </mat-error>
            <mat-error *ngIf="agencySignUpForm.controls['taxId'].errors['pattern']">
              Enter valid tax id (Example : 11-1111111).
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="address">
          <mat-label>Street Address</mat-label>
          <input matInput formControlName="address" type="text" placeholder="Street Address"
                 appNfNoSpaces [(ngModel)]="agencyModel.AddressLineOne"
                 maxlength="250">
          <mat-error *ngIf="agencySignUpForm.controls['address'].invalid && (agencySignUpForm.controls['address'].dirty || agencySignUpForm.controls['address'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['address'].errors['required'] || agencySignUpForm.controls['address'].errors['whitespace']">
              Street address is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="city">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" type="text" placeholder="City" appNfNoSpaces
                 formControlName="city" [(ngModel)]="agencyModel.City" maxlength="50">
          <mat-error *ngIf="agencySignUpForm.controls['city'].invalid && (agencySignUpForm.controls['city'].dirty || agencySignUpForm.controls['city'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['city'].errors['required'] ||agencySignUpForm.controls['city'].errors['whitespace']">
              City is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="state">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" id="select1" [(ngModel)]="agencyModel.State"
                      (selectionChange)="SelectState($event.value)">
            <mat-option value="null">Select</mat-option>
            <mat-option [value]="state.stateId" *ngFor="let state of states">{{state.stateName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="agencySignUpForm.controls['state'].invalid && (agencySignUpForm.controls['state'].dirty || agencySignUpForm.controls['state'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['state'].errors['required']">
              State is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="zip">
          <mat-label>Zip</mat-label>
          <input matInput formControlName="zip" id="zip" type="text" placeholder="Zip" appNfNoSpaces
                 (change)="getCountyList(agencyModel.Zip);" [(ngModel)]="agencyModel.Zip"
                 maxlength="5" (input)="ResetInvalidZip();isNumberKey($event)" (keypress)="numberOnly($event)">
          <mat-error *ngIf="agencySignUpForm.controls['zip'].invalid && (agencySignUpForm.controls['zip'].dirty || agencySignUpForm.controls['zip'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['zip'].errors['required'] || agencySignUpForm.controls['zip'].errors['whitespace']">
              Zip is required.
            </mat-error>
            <mat-error *ngIf="!agencyModel.isZipCodeExist && !agencySignUpForm.controls['zip'].errors['required']">
              Invalid zip.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="county">
          <mat-label>County</mat-label>
          <mat-select formControlName="county" id="select2" [(ngModel)]="agencyModel.County"
                      (selectionChange)="OnCountyDeselect($event.value);">
            <mat-option value="null">Select</mat-option>
            <mat-option [value]="county.countyId" *ngFor="let county of counties">{{county.countyName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="agencySignUpForm.controls['county'].invalid && (agencySignUpForm.controls['county'].dirty || agencySignUpForm.controls['county'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['county'].errors['required']">
              County is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <h2>
        <i class="fa-solid fa-play"></i>
        Agency Administrator
      </h2>
      <div class="form admin-form">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput id="firstName" appNfNoSpaces
                 type="text" maxlength="50" placeholder="First Name" [(ngModel)]="agencyModel.FirstName"
                 formControlName="firstName" />
          <mat-error *ngIf="agencySignUpForm.controls['firstName'].invalid && (agencySignUpForm.controls['firstName'].dirty || agencySignUpForm.controls['firstName'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['firstName'].errors['required'] || agencySignUpForm.controls['firstName'].errors['whitespace']">
              First name is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput id="lastName" type="text"
                 placeholder="Last Name" maxlength="50" appNfNoSpaces [(ngModel)]="agencyModel.LastName"
                 formControlName="lastName" />
          <mat-error *ngIf="agencySignUpForm.controls['lastName'].invalid && (agencySignUpForm.controls['lastName'].dirty || agencySignUpForm.controls['lastName'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['lastName'].errors['required'] || agencySignUpForm.controls['lastName'].errors['whitespace']">
              Last name is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Primary Email</mat-label>
          <input matInput id="primaryEmail" type="text"
                 placeholder="Primary Email" appNfNoSpaces
                 [(ngModel)]="agencyModel.PrimaryEmail" formControlName="primaryEmail" maxlength="100" />
          <mat-error *ngIf="agencySignUpForm.controls['primaryEmail'].invalid && (agencySignUpForm.controls['primaryEmail'].dirty || agencySignUpForm.controls['primaryEmail'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['primaryEmail'].errors['required'] || agencySignUpForm.controls['primaryEmail'].errors['whitespace']">
              Primary email is required.
            </mat-error>
            <mat-error *ngIf="agencySignUpForm.controls['primaryEmail'].errors['email'] || agencySignUpForm.controls['primaryEmail'].errors['pattern']">
              Primary email must be a valid email address.
            </mat-error>
            <mat-error *ngIf="agencySignUpForm.controls['primaryEmail'].errors['emailExist']">
              The email address you have entered is already registered, please try with different email address.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Secondary Email</mat-label>
          <input matInput id="recoveryEmail" type="text"
                 placeholder="Secondary Email" [(ngModel)]="agencyModel.RecoveryEmail"
                 formControlName="recoveryEmail" maxlength="100" />
          <mat-error *ngIf="agencySignUpForm.controls['recoveryEmail'].invalid && (agencySignUpForm.controls['recoveryEmail'].dirty || agencySignUpForm.controls['recoveryEmail'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['recoveryEmail'].errors['email']">
              Recovery email must be a valid email address.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Mobile Phone</mat-label>
          <input matInput id="mobile" type="tel"
                 (keypress)="numberOnly($event)" maxlength="20" appNfNoSpaces placeholder="Mobile Phone"
                 [(ngModel)]="agencyModel.Mobile" formControlName="mobile" [textMask]="{mask: mask}" inputmode="numeric"
                 digitOnly (input)="formatPhoneNumber(agencySignUpForm.controls['mobile'],1)" />
          <mat-error *ngIf="agencySignUpForm.controls['mobile'].invalid && (agencySignUpForm.controls['mobile'].dirty || agencySignUpForm.controls['mobile'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['mobile'].errors['required'] || agencySignUpForm.controls['mobile'].errors['whitespace']">
              Mobile phone is required.
            </mat-error>
            <mat-error *ngIf="agencySignUpForm.controls['mobile'].errors['pattern']">
              Mobile phone must be a valid.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Home Phone</mat-label>
          <input matInput id="phone" type="tel"
                 (keypress)="numberOnly($event)" formControlName="phone" placeholder="Home Phone"
                 [(ngModel)]="agencyModel.Phone" (input)="formatPhoneNumber(agencySignUpForm.controls['phone'],2)"
                 [textMask]="{mask: mask}" inputmode="numeric" digitOnly />
          <mat-error *ngIf="agencySignUpForm.controls['phone'].invalid && (agencySignUpForm.controls['phone'].dirty || agencySignUpForm.controls['phone'].touched)">
            <mat-error *ngIf="agencySignUpForm.controls['phone'].errors['pattern']">
              Home phone must be a valid.
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <ngx-recaptcha2 #captchaElem formControlName="recaptcha" [siteKey]="siteKey"></ngx-recaptcha2>
        <button mat-flat-button type="submit" color="primary" aria-label="Submit" class="hvr-grow-shadow">Submit</button>
      </div>
    </div>
  </div>

  <ng-template #contentAlert let-modal>
    <div class="modal-header">
      <h1 class="modal-title" id="modal-basic-title">
        <i class="fas fa-check"></i>
        Alert
      </h1>
    </div>
    <div class="modal-body">
      <p class="text-center">
        {{alertMessage}}
      </p>
    </div>
    <div class="modal-footer no-top-border">
      <button class="btn btn-primary" (click)="modal.close();">Ok</button>
    </div>
  </ng-template>
</form>
